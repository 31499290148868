import React from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import NumberEasing from 'react-number-easing'
import {useInView} from 'react-intersection-observer'

import {MainLayout} from 'layouts'
import {AnchorButton, LinkButton, SEO} from 'components'
import {BarChart} from 'images/svgs'
import BackgroundSVG from 'images/background-small.svg'

const BasicSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`

const BasicContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    max-width: var(--content-width);
  }
`

const HeroSection = styled(BasicSection)`
  padding: 50px 0;
  background: center / cover no-repeat url(${BackgroundSVG});
  background-color: var(--white);
`

const HeroContent = styled(BasicContent)`
  text-align: center;

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const MissionSection = styled(BasicSection)`
  padding: 50px 0;
`

const MissionContent = styled(BasicContent)`
  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const Statement = styled.div``

const OpenStartupSection = styled(BasicSection)`
  padding: 50px 0;
  background-color: var(--white);
`

const OpenStartupContent = styled(BasicContent)`
  text-align: center;

  p {
    margin-bottom: 32px;
  }
`

const StyledBarChart = styled(BarChart)`
  width: 100px;
`

const VisionSection = styled(BasicSection)`
  padding: 25px 0;

  background-color: var(--dark-blue);
  color: var(--white);
`

const VisionContent = styled(BasicContent)`
  text-align: center;

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const Goals = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`

const Goal = styled.p`
  margin-bottom: 32px;
  font-weight: 600;

  @media only screen and (min-width: 700px) {
    max-width: 280px;
  }
`

const GoalNumberStyle = styled.strong`
  font-weight: 800;
  font-size: 4rem;

  @media only screen and (min-width: 700px) {
    font-weight: 800;
    font-size: 3.5rem;
  }
`

const TeamSection = styled(BasicSection)`
  padding: 25px 0;
  background-color: var(--white);
`

const TeamContent = styled(BasicContent)`
  text-align: center;
`

const Profiles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    max-width: 1000px;
  }
`

const Profile = styled.div`
  margin-bottom: 25px;

  text-align: center;

  h4 {
    margin-bottom: 0;
  }
`

const ProfilePicture = styled(Img)`
  width: 100px;
  height: 100px;
  border-radius: 50px;

  @media only screen and (min-width: 1000px) {
    width: 150px;
    height: 150px;
    border-radius: 75px;
  }
`

const Schools = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`

const School = styled(Img)`
  width: 100%;
  height: 100px;

  margin: 25px 0;

  @media only screen and (min-width: 700px) {
    width: 200px;
    margin: 25px;
  }
`

const InviteSection = styled(BasicSection)`
  padding: 50px 0;
`

const InviteContent = styled(BasicContent)`
  text-align: center;

  h2 {
    margin-top: 0;
  }
`

const GoalNumber = ({number}) => {
  const {ref, inView} = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  return (
    <GoalNumberStyle ref={ref}>
      <NumberEasing
        value={inView ? number : 0}
        speed={1000}
        ease='cubicInOut'
        customFunctionRender={(currentValue) => Math.floor(Number(currentValue)).toLocaleString('en')}
      />
    </GoalNumberStyle>
  )
}

export const AboutPage = ({data}) => (
  <MainLayout>
    <SEO
      title='About'
      description='Our mission: to help students become creators. Creators are the visionaries. Those that can take their ideas, and build it with code. Creators are limitless. Bugs...'
    />
    <HeroSection>
      <HeroContent>
        <h1>
          The future of creating
          <br />
          belongs to everyone
        </h1>
      </HeroContent>
    </HeroSection>
    <MissionSection>
      <MissionContent>
        <Statement>
          <h2>Our Mission</h2>
          <p>We exist to help students become creators.</p>
          <p>
            Creators are the visionaries. Those that can take their ideas, and build it with code. Creators are
            limitless. Bugs and merge conflicts can't stop them in their passion for building. Creators are the ones who
            realize that coding is a superpower, and become our generation's superheroes with their creations.
          </p>
        </Statement>
        <Statement>
          <h2>Our Values</h2>
          <p>The Codex was founded in August 2015 after we realized that modern-day CS education is lacking.</p>

          <p>
            In the spirit of building, The Codex is a Open Startup, which means we operate fully transparent and share
            our metrics like revenue and traffic. Explore our Open Startup page here.
          </p>

          <p>
            The more makers who use The Codex and the most customers we have mean we can keep helping students become
            creators. Every dollar made at The Codex gets reinvested into the business to make the future of creating
            belong to everyone.
          </p>
        </Statement>
      </MissionContent>
    </MissionSection>
    <OpenStartupSection>
      <OpenStartupContent>
        <StyledBarChart />
        <h2>Open Startup</h2>
        <p>The Codex is an open startup with publicly viewable business metrics!</p>
        <LinkButton tier='primary' to='/open-startup'>
          View Metrics
        </LinkButton>
      </OpenStartupContent>
    </OpenStartupSection>
    <VisionSection>
      <VisionContent>
        <h2>Our Vision</h2>
        <Goals>
          <Goal>
            <GoalNumber number={10000} />
            <br />
            students land their dream internship or job.
          </Goal>
          <Goal>
            <GoalNumber number={20000} />
            <br />
            students learn Python and Data Science every month.
          </Goal>
          <Goal>
            <GoalNumber number={100000} />
            <br />
            ideas go from a students{`’`} imagination to reality.
          </Goal>
        </Goals>
      </VisionContent>
    </VisionSection>
    <TeamSection>
      <TeamContent>
        <h2>Meet the Team</h2>
        <Profiles>
          <Profile>
            <ProfilePicture fluid={data.avi.childImageSharp.fluid} alt='Picture of Avinash' />
            <h4>Avinash</h4>
            <p>Co-Founder</p>
          </Profile>
          <Profile>
            <ProfilePicture fluid={data.sameer.childImageSharp.fluid} alt='Picture of Sameer' />
            <h4>Sameer</h4>
            <p>Co-Founder</p>
          </Profile>
          <Profile>
            <ProfilePicture fluid={data.jojo.childImageSharp.fluid} alt='Picture of Jojo' />
            <h4>Jojo</h4>
            <p>Designer</p>
          </Profile>
          <Profile>
            <ProfilePicture fluid={data.sreya.childImageSharp.fluid} alt='Picture of Sreya' />
            <h4>Sreya</h4>
            <p>Marketing</p>
          </Profile>
          <Profile>
            <ProfilePicture fluid={data.wal.childImageSharp.fluid} alt='Picture of Wal' />
            <h4>Wal</h4>
            <p>Web Developer</p>
          </Profile>
        </Profiles>
        <p>
          <strong>We’re college students...we know how hard it is learn how to code.</strong>
        </p>
        <Schools>
          <School imgStyle={{objectFit: 'contain'}} fluid={data.purdue.childImageSharp.fluid} alt='Purdue University' />
          <School imgStyle={{objectFit: 'contain'}} fluid={data.berkley.childImageSharp.fluid} alt='UC Berkly' />
          <School
            imgStyle={{objectFit: 'contain'}}
            fluid={data.carleton.childImageSharp.fluid}
            alt='Carleton Unviersity'
          />
          <School
            imgStyle={{objectFit: 'contain'}}
            fluid={data.virginia.childImageSharp.fluid}
            alt='University of Virginia'
          />
        </Schools>
      </TeamContent>
    </TeamSection>
    <InviteSection>
      <InviteContent>
        <h2>Get Involved</h2>
        <p>
          Want to work with us? Email us at{' '}
          <AnchorButton tier='tertiary' href='mailto:team@thecodex.me'>
            <strong>team@thecodex.me</strong>
          </AnchorButton>
          .
        </p>
      </InviteContent>
    </InviteSection>
  </MainLayout>
)

export default AboutPage

export const AboutData = graphql`
  query AboutData {
    avi: file(relativePath: {eq: "avi.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jojo: file(relativePath: {eq: "jojo.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sameer: file(relativePath: {eq: "sameer.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sreya: file(relativePath: {eq: "sreya.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wal: file(relativePath: {eq: "wal.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    purdue: file(relativePath: {eq: "purdue.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    berkley: file(relativePath: {eq: "berkley.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carleton: file(relativePath: {eq: "carleton.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    virginia: file(relativePath: {eq: "virginia.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
